import React, { useState } from "react";
import { BiBuildings } from "react-icons/bi";
import Link from "next/link";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import { capture } from "../../../utils/analytics/capture";

function PropertyHit({ hit, sendEvent }) {
  const [loading, setLoading] = useState(false);

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: "10%",
    onChange: (inView) => {
      if (inView) {
        if (sendEvent) {
          // send to algolia , sendEvent()
        }

        capture({
          category: "search",
          event: "impression",
          properties: {
            ...hit,
            type: "property",
          },
        });
      }
    },
  });
  return (
    <Link href={`/homes/${hit.slugShort}`} passHref>
      <a
        ref={ref}
        onClick={() => {
          setLoading(true);
          capture({
            category: "search",
            event: "impression",
            properties: {
              ...hit,
              type: "property",
            },
          });
        }}
        className=" group flex cursor-pointer flex-row items-center space-x-3 bg-white px-5 py-2 transition-all duration-300 hover:bg-ula-background-light"
      >
        <div className=" flex h-8 w-8 flex-shrink-0  items-center justify-center rounded-full bg-ula-background-light  group-hover:bg-green-50 group-hover:shadow ">
          <BiBuildings className=" h-4 w-4 text-ula-primary" />
        </div>
        <div className=" flex w-7/12 flex-shrink-0 flex-col">
          <span className=" cursor-pointer truncate   text-gray-800">
            {hit.propertyName
              ? hit.propertyName
              : `${
                  hit.noOfBedrooms === 0
                    ? hit.propertyType +
                      " in " +
                      `${hit.propertyTown?.townName}`
                    : hit.noOfBedrooms +
                      " bedroom, " +
                      hit.propertyType +
                      `, ${hit.propertyTown?.townName}`
                }`}
          </span>
          <span
            onClick={() => {}}
            className=" cursor-pointer  truncate text-sm font-light text-gray-800 group-hover:text-gray-800"
          >
            {!hit.propertyArea?.address_components[0].types.includes(
              "plus_code"
            )
              ? hit.propertyArea?.address_components[0].long_name
              : hit.propertyArea?.areaName.substring(
                  0,
                  hit.propertyArea?.areaName.indexOf(",")
                )}
            , {hit.propertyTown?.townName}
          </span>
        </div>
        <div className=" flex w-1/3 justify-end pr-0">
          {loading ? (
            <AiOutlineLoading3Quarters className=" animate-spin justify-end text-gray-800" />
          ) : null}
        </div>
      </a>
    </Link>
  );
}

export default PropertyHit;
