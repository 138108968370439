export const getUrl = () => {
  const url = process.env.NEXT_PUBLIC_VERCEL_URL
    ? process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "https://" + "ula.africa"
      : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
    : "http://localhost:3000";

  return url;
};

export const getOGimage = () => {
  return "https://firebasestorage.googleapis.com/v0/b/ulaafrica.appspot.com/o/assets%2FUla%20OG_1080x1080.webp?alt=media&token=2df85b95-6628-49d0-8d0d-7c5de580c6ec";
};
