import React, { useState } from "react";
import { useRouter } from "next/router";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import { IoLocationSharp } from "react-icons/io5";
import { getUrl } from "../../../utils/getUrl";
import { capture } from "../../../utils/analytics/capture";

function TownHit({ hit, source, sendEvent, setResultsOpen }) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: "10%",
    onChange: (inView) => {
      if (inView) {
        //  console.log( hit.propertyName+ " :In View")

        capture({
          category: "search",
          event: "impression",
          properties: {
            ...hit,
            type: "town",
          },
        });
      }
    },
  });
  return (
    // <Link
    //   href={{
    //     pathname: `/town/${hit.townSlug}`,
    //   }}
    //   as={`/town/${hit.townSlug}`}
    // >
    <div
      ref={ref}
      onClick={() => {
        capture({
          category: "search",
          event: "impression",
          properties: {
            ...hit,
            type: "town",
          },
        });

        if (source === "TOWN") {
          setResultsOpen(false);
          window.open(
            `${getUrl()}/town/${hit.townSlug}`,
            "_blank",
            "noopener,noreferrer"
          );
          return;
        }

        setLoading(true);
        router.push(`/town/${hit.townSlug}`);
      }}
      className=" group flex cursor-pointer flex-row items-center space-x-3 bg-white px-5 py-2 transition-all duration-300 hover:bg-ula-background-light"
    >
      <div className=" w-8">
        {/* <div className=" rounded-full bg-blue-100">
            <IoLocationSharp className="h-5 w-5 text-ula-primary" />
          </div> */}
        <div className=" flex h-8 w-8 flex-shrink-0  items-center justify-center rounded-full bg-ula-background-light  group-hover:bg-green-50 group-hover:shadow ">
          <IoLocationSharp className=" h-4 w-4 text-ula-primary " />
        </div>
      </div>
      <div className=" flex w-7/12 flex-col">
        <span
          onClick={() => {}}
          className=" cursor-pointer  truncate text-gray-800   group-hover:font-medium"
        >
          {hit.townName}
        </span>
        <span className=" cursor-pointer truncate  text-sm font-light text-gray-800 group-hover:text-gray-800">
          {hit.townName}, {hit.countyName}
        </span>
      </div>

      <div className=" flex w-1/3 justify-end">
        {loading ? (
          <AiOutlineLoading3Quarters className=" animate-spin justify-end text-gray-800" />
        ) : null}
      </div>
    </div>
    // </Link>
  );
}

export default TownHit;
