import React, { useState } from "react";
import Link from "next/link";
import { CgProfile } from "react-icons/cg";
import Image from "next/image";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { useInView } from "react-intersection-observer";
import { MdPhoneEnabled } from "react-icons/md";
import { useRouter } from "next/router";
import { ImArrowRight2 } from "react-icons/im";
import { capture } from "../../../utils/analytics/capture";

function ListerHit({ hit, sendEvent }) {
  const [loading, setLoading] = useState(false);

  const phone = "+254" + hit.listerInfo.listerPhone;
  const router = useRouter();

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: "10%",
    onChange: (inView) => {
      if (inView) {
        //  console.log( hit.propertyName+ " :In View")

        capture({
          category: "search",
          event: "impression",
          properties: {
            type: "lister",
            townID: hit.listerInfo.listerTown?.townID,
            townName: hit.listerInfo.listerTown?.townName,
            countyID: hit.listerInfo.listerCounty?.countyID,
            countyName: hit.listerInfo.listerCounty?.countyName,
            listerID: hit.objectID,
            listerName: hit.listerInfo.listerName,
          },
        });
      }
    },
  });
  return (
    <>
      {hit.createdBy === "ULA" && !hit.profileClaimed ? (
        <div
          ref={ref}
          className=" group flex cursor-pointer flex-row items-center space-x-3 bg-white px-5 py-2 transition-all duration-300 hover:bg-ula-background-light"
        >
          {hit["auth.photo"] && hit["auth.photo"] !== "https://default" ? (
            <div className="relative h-8 w-8 flex-shrink-0">
              <Image
                src={hit["auth.photo"]}
                className=" flex h-8 w-8  rounded-full object-cover"
                priority
                alt="Agent"
                layout="fill"
              />
            </div>
          ) : (
            <div className=" flex h-8 w-8 flex-shrink-0  items-center justify-center rounded-full bg-blue-100 ">
              <CgProfile className=" h-4 w-4 text-ula-primary" />
            </div>
          )}

          <div className=" flex w-7/12 flex-col">
            <span className="   cursor-default  truncate text-gray-800">
              {hit.listerInfo.listerName}
            </span>
            <span
              onClick={() => {}}
              className="  cursor-default  truncate text-sm font-light text-gray-800 group-hover:text-gray-800"
            >
              {hit.listerInfo.listerTown.townName},{" "}
              {hit.listerInfo.listerCounty?.countyName}
            </span>
          </div>

          <div className="   flex w-1/3 justify-end pr-0">
            <a
              onClick={() => {
                capture({
                  category: "lead",
                  event: "contact_click",
                  properties: {
                    type: "lister",
                    areaName: hit.listerInfo?.areaName,
                    areaID: hit.listerInfo?.areaID,
                    countyID: hit.listerInfo.listerCounty?.countyID,
                    countyName: hit.listerInfo.listerCounty?.countyName,
                    townID: hit.listerInfo.listerTown?.townID,
                    townName: hit.listerInfo.listerTown?.townName,
                    listerName: hit.listerInfo?.listerName,
                    listerID: hit.objectID,
                  },
                });
              }}
              href={`tel:${phone}`}
              className=" hidden group-hover:flex"
            >
              <div className="  cursor-pointer rounded-full bg-transparent p-2 hover:bg-blue-200">
                <MdPhoneEnabled className=" h-5 w-5 text-gray-800 group-hover:text-ula-secondary" />
              </div>
            </a>
          </div>
        </div>
      ) : (
        <Link
          href={
            hit.createdBy === "ULA" && !hit.profileClaimed
              ? `/u/${hit.listerInfo.listerUserName}`
              : `/${hit.listerInfo.listerUserName}`
          }
        >
          <div
            ref={ref}
            onClick={() => {
              setLoading(true);
              sendEvent("click", hit, "lister_clicked");
              capture({
                category: "search",
                event: "impression",
                properties: {
                  ...hit,
                  type: "lister",
                },
              });
            }}
            className=" group flex cursor-pointer flex-row items-center space-x-3 bg-white px-5 py-2 transition-all duration-300 hover:bg-ula-background-light"
          >
            {hit["auth.photo"] && hit["auth.photo"] !== "https://default" ? (
              <div className="relative h-8 w-8 flex-shrink-0">
                <Image
                  src={hit["auth.photo"]}
                  className=" flex h-8 w-8  rounded-full object-cover"
                  priority
                  layout="fill"
                  alt="Agent"
                />
              </div>
            ) : (
              <div className=" flex h-8 w-8 flex-shrink-0  items-center justify-center rounded-full bg-ula-background-light  group-hover:bg-green-50 group-hover:shadow  ">
                <CgProfile className=" h-4 w-4 text-ula-primary" />
              </div>
            )}

            <div className=" flex w-7/12 flex-col">
              <span className="   cursor-pointer  truncate text-gray-800   group-hover:font-medium">
                {hit.listerInfo.listerName}
              </span>
              <span
                onClick={() => {}}
                className=" truncate  text-sm font-light text-gray-800 group-hover:text-gray-700"
              >
                {hit.listerInfo.listerTown.townName},{" "}
                {hit.listerInfo.listerCounty?.countyName}
              </span>
            </div>

            <div className=" flex w-1/3 justify-end pr-0">
              {!loading ? (
                <div className=" hidden group-hover:flex">
                  <div className=" cursor-pointer rounded-full bg-transparent p-2 hover:bg-blue-200">
                    <ImArrowRight2 className=" h-4 w-4 group-hover:text-ula-secondary" />
                  </div>
                </div>
              ) : null}
              {loading ? (
                <AiOutlineLoading3Quarters className=" animate-spin justify-end text-gray-800" />
              ) : null}
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default ListerHit;
